import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import i18n from '../i18n';

import enIcon from '../images/EN.svg';
import dkIcon from '../images/DK.svg';

class QuickLinks extends Component {
  constructor(props) {
    super(props)

    this.state = {enLng: true}
  }

  render() {
    return (
      <div>
        <a className="faq-p"
          target="_blank"
          rel="noopener noreferrer"
          href={"https://sites.google.com/neutral.gs/coa/faqs-en"}
          onClick={(e) => {
            ReactGA.event({
              category: "customer",
              action: "quicklinks",
              label: "faq"
            })
          }}>{i18n.t("faq")}</a>

        <div className="quick-link web">
          <div style={{marginBottom: 10}}>
            <img src={dkIcon} className="footer-item quick-link icon-right" alt=""
              style={{opacity: !this.state.enLng ? 1 : 0.3}}
              onClick={(e) => {
                e.preventDefault()

                ReactGA.event({
                  category: "customer",
                  action: "quicklinks",
                  label: "language dk"
                })

                this.setState({enLng: false})
                i18n.changeLanguage("dk");
              }}/>

            <img src={enIcon} className="footer-item quick-link" alt=""
              style={{opacity: this.state.enLng ? 1 : 0.3}}
              onClick={(e) => {
                e.preventDefault()

                ReactGA.event({
                  category: "customer",
                  action: "quicklinks",
                  label: "language en"
                })

                this.setState({enLng: true})
                i18n.changeLanguage("en");
              }}/>
          </div>

          <div>
            <a style={{color: "black"}}
              target="_blank"
              rel="noopener noreferrer"
              href={"https://sites.google.com/neutral.gs/coa/tncs-en"}
              onClick={(e) => {
                ReactGA.event({
                  category: "customer",
                  action: "quicklinks",
                  label: "tnc"
                })
              }}>{i18n.t("tnc")}</a>

            <span>{" | "}</span>

            <a style={{color: "black"}}
              target="_blank"
              rel="noopener noreferrer"
              href={"https://sites.google.com/neutral.gs/coa/pp-en"}
              onClick={(e) => {
                ReactGA.event({
                  category: "customer",
                  action: "quicklinks",
                  label: "privacy policy"
                })
              }}>{i18n.t("privacy_policy")}</a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(QuickLinks)
