import React, { Component } from 'react';

import LockersButton from 'cph-lockers-library'
import TopBanner from './TopBanner'

import icon from '../images/Graphic_Heaeder_black.svg';

export default class DiscardView extends Component {
  constructor(props) {
    super(props);

    this.negativeClicked = this.props.onNegativeClicked;
    this.positiveClicked = this.props.onPositiveClicked;
  }

  render() {
    return (
      <div>
        <TopBanner
          backgroundColor="#DFE3E6"
          textColor="black"
          icon={icon}
          title="Collect on Arrival"
          subtitle="Cancel Changes?"/>

        <div className="overlay-view">
          <p className="header">Are You Sure?</p>

          <p className="body">Clicking YES will discard all changes you have made during this session.</p>

          <div className="buttons">
            <LockersButton className="button-grey margin-right" link="/" onClick={this.negativeClicked}>NO</LockersButton>
            <LockersButton className="button margin-left" link="/" onClick={this.positiveClicked}>YES</LockersButton>
          </div>
        </div>
      </div>
    );
  }
}
