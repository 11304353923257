import React, { Component } from 'react';

import ReactGA from 'react-ga';

import LockersButton, {
  NetworkManager,
  FormValidator,
  getErrorMessage
} from 'cph-lockers-library'
import QuickLinks from '../components/QuickLinks'
import qs from 'qs'

import i18n from '../i18n';

import icon from '../images/graphic_User_Keypads.svg'

import './Screen.css'

export default class CustomerStartScreen extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'last_name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please fill this field.'
      },
      {
        field: 'order_id',
        method: 'isEmpty',
        validWhen: false,
        message: 'Please fill the Order Number.'
      }
    ]);

    NetworkManager.clearToken();

    // check if params are included in the url
    var params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    var last_name = params.last_name != null ? params.last_name : ""
    var order_id = params.order_id != null ? params.order_id : ""

    if (last_name.length > 0) {
      ReactGA.event({
        category: "customer",
        action: "opened from email"
      })
    }

    this.state = {
      validation: this.validator.valid(),
      last_name: last_name,
      order_id: order_id,
      errorMessage: ""
    }

    this.submitted = false;
    this.onDataChange = this.props.onChangeData;
  }

  handleInputChange = event => {
    event.preventDefault();

    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormSubmit = event => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    ReactGA.event({
      category: "customer",
      action: "get login code"
    })

    var data = {}
    data.last_name = this.state.last_name
    data.order_id = this.state.order_id.toUpperCase()

    if (validation.isValid) {
      NetworkManager.post("/generate-code/", qs.stringify(data), true)
      .then((response) => {
        // console.log(response)
        if (response.data.error != null) {
          this.setState({errorMessage: getErrorMessage(response)})
          return;
        }

        this.onDataChange(data)
        this.props.history.push("/code")
      })
      .catch((error) => {
        // console.log(error)
        this.setState({errorMessage: getErrorMessage(error.response)})
      })
    }
  }

  handleKeyPress = key => {
    if (key.key === 'Enter') {
      this.handleFormSubmit(key)
    }
  }

  render() {
    this.validation = this.submitted ?
                      this.validator.validate(this.state) :
                      this.state.validation

    return (
      <div className="screen-customer">
        <div className="screen">

          <img className="img-center" height="100" width="100" src={icon} alt=""/>

          <p className="start-header">{i18n.t("customer_welcome")}</p>
          <p className="header" style={{marginTop: 0}}>{i18n.t("customer_coa")}</p>
          <p style={{color: "black", fontSize: 14, fontWeight: '200', textAlign: 'center'}}>{i18n.t("customer_manage_order")}</p>

          <div className={this.validation["last_name"].isInvalid ? 'has-error box' : "box"}>
            <label className="startscreen-label" htmlFor="last_name">{i18n.t("last_name")}</label>
            <input type="text" className="startscreen-input-field"
              name="last_name"
              value={this.state.last_name}
              onChange={this.handleInputChange}
              onKeyPress={this.handleKeyPress}
            />
            <span className="help-block">{this.validation["last_name"].message}</span>
          </div>

          <div className={this.validation["order_id"].isInvalid ? 'has-error box' : "box"}>
            <p className="startscreen-label" htmlFor="order_id">{i18n.t("customer_order_nr")}</p>
            <input type="text" className="startscreen-input-field capitalize"
              name="order_id"
              value={this.state.order_id}
              onChange={this.handleInputChange}
              onKeyPress={this.handleKeyPress}
            />
            <span className="help-block">{this.validation["order_id"].message}</span>
          </div>

          <p className="help-block">{this.state.errorMessage}</p>

          <LockersButton link="" onClick={this.handleFormSubmit} className="button" style={{marginTop: 20}}>{i18n.t("customer_login_code")}</LockersButton>

          <QuickLinks />

        </div>
      </div>
    );
  }
}
