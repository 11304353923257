import React, { Component } from 'react';

export default class TopBanner extends Component {
  render() {
    return (
      <div className="banner" style={{backgroundColor: this.props.backgroundColor}}>
        <div>
          <p className="banner-title"  style={{color: this.props.textColor}}>{this.props.title}</p>
          <p className="banner-subtitle" style={{color: this.props.textColor}}>{this.props.subtitle}</p>
        </div>

        <img src={this.props.icon} height="50" width="50" alt="" />
      </div>
    );
  }
}
