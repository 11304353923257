import React, { Component } from 'react';

import LockersButton from 'cph-lockers-library'

import i18n from '../i18n';

import icon from '../images/graphic_User_Keypads.svg';

export default class NoChangesView extends Component {
  constructor(props) {
    super(props);

    this.negativeClicked = this.props.onNegativeClicked;
    this.positiveClicked = this.props.onPositiveClicked;
  }

  render() {
    return (
      <div className="overlay-view">
        <img src={icon} height="100" width="100" alt=""
          style={{marginTop: 50, marginBottom: 40}} />

        <p className="header">{i18n.t("customer_no_changes")}</p>

        <p className="body">
        {i18n.t("customer_not_updated_1")}<br></br><br></br>{i18n.t("customer_not_updated_2")}
        </p>

        <div className="buttons">
          <LockersButton className="button margin-left" link="/" onClick={this.positiveClicked}>{i18n.t("customer_change_info")}</LockersButton>
          <LockersButton className="button-grey margin-right" link="/" onClick={this.negativeClicked}>{i18n.t("customer_home")}</LockersButton>
        </div>
      </div>
    );
  }
}
