import React, { Component } from 'react';

import { Container, Row, Col } from 'react-grid-system';
import ReactGA from 'react-ga';

import moment from 'moment'

import i18n from '../i18n';

import LockersButton, {
  NetworkManager,
  withGA,
  PhoneUtils,
  getErrorMessage
} from 'cph-lockers-library'

import QuickLinks from '../components/QuickLinks'
import DiscardView from '../components/DiscardView'
import TopBanner from '../components/TopBanner'

import icon from '../images/Graphic_Heaeder.svg'

const SUCCESS = 200;

class CustomerReviewScreen extends Component {
  constructor(props) {
    super(props);

    var dataForReview = this.props.data();
    this.dataComparison = dataForReview.comparison;

    this.state = {
      showDicardView: false,
      data: dataForReview.data,
      errorCode: "",
    }

    this.history = this.props.history;
    this.onDataChange = this.props.onChangeData;
  }

  componentDidMount() {
    this.id = this.props.data().order_id;
  }

  createField(title, name) {
    var val = this.state.data[name];

    if (name === "phone_country_code") {
      val = PhoneUtils.getCountryName(val)
    }

    return (
      <div>
        <p className="title">{title}</p>
        <p className={this.dataComparison[name] ? "changed-data" : ""}>{val}</p>
      </div>
    )
  }

  handleFormSubmit = event => {
    var finalData = this.state.data;

    var datetime = moment(finalData.arrival_datetime, "DD/MM/YYYY");
    datetime.set('hour', finalData.arrivalTime.split(":")[0])
    datetime.set('minute', finalData.arrivalTime.split(":")[1])

    finalData.arrival_datetime = datetime.format("DD-MM-YYYY");

    NetworkManager.put("/orders/" + this.id + "/", finalData.stringify(), true)
    .then((response) => {
      // console.log(response)
      if (response.status === SUCCESS) {
        this.history.push("/complete")

        ReactGA.event({
          category: "customer",
          action: "submit details",
          label: "customer"
        })
      }
    })
    .catch((response => {
      ReactGA.event({
        category: "customer",
        action: "submit details",
        label: "error"
      })

      this.setState({errorCode: getErrorMessage(response)});
    }));
  }

  render() {
    if (!this.state.data) {
      return null;
    }

    if (this.state.showDicardView) {
      return <DiscardView
        onNegativeClicked={(e) => {
          e.preventDefault();

          ReactGA.event({
            category: "customer",
            action: "discard changes",
            label: "no"
          })

          this.setState({showDicardView: false});
        }}
        onPositiveClicked={(e) => {
          e.preventDefault();

          ReactGA.event({
            category: "customer",
            action: "dicard changes",
            label: "yes"
          })

          this.onDataChange({});
          this.history.push("/");
        }}
        />
    }

    return (
      <div className="screen-customer">
        <TopBanner
          backgroundColor="#C4262E"
          textColor="white"
          icon={icon}
          title={i18n.t("customer_coa")}
          subtitle={i18n.t("customer_review_changes")}/>

        <p className="help-block">{this.state.errorCode}</p>

        <Container fluid style={{margin: 20, marginTop: 50}}>
          <Row>
            <Col md={4} sm={12}>
              <p className="title">{i18n.t("customer_barcode")}</p>
              <p className="detail-body">{this.state.data.receipt_no}</p>

              <p className="title">{i18n.t("first_name")}</p>
              <p className="detail-body">{this.state.data.first_name}</p>

              <p className="title">{i18n.t("last_name")}</p>
              <p className="detail-body">{this.state.data.last_name}</p>

              <p className="title">{i18n.t("customer_package_nr")}</p>
              <p className="detail-body">{this.state.data.number_of_packages}</p>

            </Col>
            <Col md={4} sm={12}>
              {this.createField(i18n.t("arrival_datetime"), "arrival_datetime")}
              {this.createField(i18n.t("arrivalTime"), "arrivalTime")}
              {this.createField(i18n.t("flight_no"), "flight_no")}
            </Col>
            <Col md={4} sm={12}>
              {this.createField(i18n.t("customer_country_code"), "phone_country_code")}
              {this.createField(i18n.t("phone_number"), "phone_number")}
              {this.createField(i18n.t("email"), "email")}
            </Col>
          </Row>

          <div className="buttons">
            <LockersButton className="button-red mobile-only" link="" onClick={(e) => {
              e.preventDefault();
              this.handleFormSubmit();
            }}>
              {i18n.t("customer_submit_changes")}
            </LockersButton>

            <div className="same-row-buttons">
              <LockersButton className="button-grey" link="" onClick={(e) => {
                  e.preventDefault();

                  ReactGA.event({
                    category: "customer",
                    action: "cancel",
                    label: "review details"
                  })

                  this.setState({showDicardView: true});
                }}>{i18n.t("cancel")}
              </LockersButton>

              <LockersButton link="/" className="button" onClick={(e) => {
                  e.preventDefault();

                  ReactGA.event({
                    category: "customer",
                    action: "edit details",
                    label: "customer"
                  })

                  this.history.push("/details")
                }}>
                {i18n.t("edit")}
              </LockersButton>
            </div>

            <LockersButton className="button-red web-only" link="" onClick={(e) => {
              e.preventDefault();
              this.handleFormSubmit();
            }}>
              {i18n.t("customer_submit_changes")}
            </LockersButton>
          </div>

          <QuickLinks />
        </Container>
      </div>
    );
  }
}

export default withGA(CustomerReviewScreen, false, false);
