import React, { Component } from 'react';

import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { NamespacesConsumer } from 'react-i18next';

import {
  CustomerStartScreen,
  CustomerCodeScreen,
  CustomerDetailsScreen,
  CustomerReviewScreen,
  CustomerCompleteScreen,
  CustomerFAQScreen,
} from './screens';

import Footer from './components/Footer'

import { TermsScreen, PolicyScreen } from 'cph-lockers-library'

class Customer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: "en",
      data: {}
    };
  }

  changeData(data) {
    this.setState({
      data: data
    });
  }

  getData() {
    return this.state.data;
  }

  handleTermsOrPolicyBack = event => {
    event.preventDefault();
    this.props.history.goBack();
  }

  render() {
    return (
      <NamespacesConsumer ns={[
      'defaultNamespace',
      'anotherNamespace']}>
      {
        (t, { i18n, ready }) => (
          <div className="content">
            <Switch className="home-content">
              <Route exact path="/" render={() => <Redirect to="/login"/>} />
              <Route exact path="/login" render={(props) => <CustomerStartScreen props{...props} data={this.getData.bind(this)} onChangeData={this.changeData.bind(this)} />} />
              <Route exact path="/code" render={(props) => <CustomerCodeScreen props{...props} data={this.getData.bind(this)} onChangeData={this.changeData.bind(this)} />} />
              <Route exact path="/details" render={() => <CustomerDetailsScreen data={this.getData.bind(this)} onChangeData={this.changeData.bind(this)} />} />
              <Route exact path="/review" render={() => <CustomerReviewScreen data={this.getData.bind(this)} onChangeData={this.changeData.bind(this)} />} />
              <Route exact path="/complete" component={CustomerCompleteScreen} />
              <Route exact path="/terms-and-conditions" render={() => <TermsScreen onClick={this.handleTermsOrPolicyBack}/>} />
              <Route exact path="/privacy-policy" render={() => <PolicyScreen onClick={this.handleTermsOrPolicyBack}/>} />
              <Route exact path="/faq" component={CustomerFAQScreen} />
              <Route render={() => <Redirect to="/login"/>} />
            </Switch>

            <Footer />
          </div>
        )
        }
      </NamespacesConsumer>
    );
  }

}

export default withRouter(Customer);
