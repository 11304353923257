import React, { Component } from 'react';

import LockersButton, { withGA } from 'cph-lockers-library'
import ReactGA from 'react-ga';

import i18n from '../i18n';

import TopBanner from '../components/TopBanner'
import QuickLinks from '../components/QuickLinks'

import icon from '../images/Graphic_Heaeder.svg'
import tickIcon from '../images/Tick.svg'

class CustomerCompleteScreen extends Component {
  render() {
    return (
      <div className="screen-customer">
        <TopBanner
          backgroundColor="#646771"
          textColor="white"
          icon={icon}
          title={i18n.t("customer_coa")}
          subtitle={i18n.t("customer_details_updated")}/>

        <div className="screen">
          <p className="header">{i18n.t("customer_thank_you")}</p>

          <img src={tickIcon} height="70" width="70" alt=""
            style={{marginTop: 40, marginBottom: 40, alignSelf: 'center'}} />

          <p className="body">
            {i18n.t("customer_updated_1")}
            <br></br>
            <br></br>
            {i18n.t("customer_updated_2")}
          </p>

          <div className="buttons">
            <LockersButton className="button" link="/"
              onClick={(e) => {
                ReactGA.event({
                  category: "customer",
                  action: "details updated"
                })
              }}
              >{i18n.t("customer_done")}</LockersButton>
          </div>

          <QuickLinks />
        </div>
      </div>
    );
  }
}

export default withGA(CustomerCompleteScreen, false, false)
