import React, { Component } from 'react';

import LockersButton from 'cph-lockers-library'

export default class CustomerFAQScreen extends Component {
  render() {
    return (
      <div className="screen-customer">

        <div className="faq-wrapper">
          <iframe title="faq" className="faq-doc" src="https://docs.google.com/document/d/e/2PACX-1vS-cPPOJ_RXXc22x2RLAzcm6G5QHEknO74qDN9XeSOuFgHQNxttcdPjneSMeUnmVsv4bMzAAqDTMaMU/pub?embedded=true"></iframe>
        </div>

        <LockersButton link="" className="button-grey"
          onClick={(e) => {
            e.preventDefault();

            this.props.history.goBack();
          }}
        >Back</LockersButton>
      </div>
    );
  }
}
