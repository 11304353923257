import React, { Component } from 'react';

import { Container, Row, Col } from 'react-grid-system';
import ReactGA from 'react-ga';

import moment from 'moment';

import i18n from '../i18n';

import DiscardView from '../components/DiscardView'
import NoChangesView from '../components/NoChangesView'
import TopBanner from '../components/TopBanner'
import QuickLinks from '../components/QuickLinks'

import icon from '../images/Graphic_Heaeder.svg'

import LockersButton, { NetworkManager, FormValidator, Profile, withGA, CountryCodeDropdown } from 'cph-lockers-library'
import 'cph-lockers-library/build/css/index.css'

import Calendar from 'react-calendar';

class CustomerDetailsScreen extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'first_name',
        method: 'isEmpty',
        validWhen: false,
        message: "fill_field"
      },
      {
        field: 'last_name',
        method: 'isEmpty',
        validWhen: false,
        message: "fill_field"
      },
      {
        field: 'arrivalTime',
        method: 'matches',
        args: [/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/],
        validWhen: true,
        message: "fill_field_time"
      },
      {
        field: 'flight_no',
        method: 'isEmpty',
        validWhen: false,
        message: "fill_field",
        optional: true
      },
      {
        field: 'phone_country_code',
        method: 'isEmpty',
        validWhen: false,
        message: "fill_field"
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: "fill_field_email"
      },
      {
        field: 'phone_number',
        method: 'isMobilePhone',
        args: ["any"],
        validWhen: true,
        message: "fill_field_phone"
      }
    ]);

    this.state = {
      validation: this.validator.valid(),
      showDicardView: false,
      showNoChangesView: false,
      validDate: true,
    }

    this.submitted = false;

    this.history = this.props.history;
    this.onDataChange = this.props.onChangeData;
  }

  componentDidMount() {
    // console.log(this.props.data())
    this.id = this.props.data().order_id;

    if (this.id == null) {
      NetworkManager.clearToken();
      this.props.history.push('/')
    }

    NetworkManager.get("/orders/" + this.id + "/", true)
    .then((response) => {
      var profile = new Profile();
      profile.fromData(response.data);
      // console.log(profile)
      this.data = profile;
      this.originalData = Object.assign({}, this.data);

      if (this.props.data().data) {
        // console.log("previous data");
        this.data = this.props.data().data;
      }

      this.setState({
        data: this.data,
        arrivalDate: moment.utc(this.data.arrival_datetime, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY"),
        arrivalTime: moment.utc(this.data.arrival_datetime, "YYYY-MM-DDTHH:mm").format("HH:mm")
      });
    })
    .catch((error) => {
      // handle error
      // console.log(error);
      this.props.history.push("/")
    })
  }

  createField(title, name) {
    var type = "text";


    if (title.includes("Email")) {
      type = "email";
    }

    var validate;
    var message;

    if (name === "arrival_datetime") {
      let date = moment(this.state.data.arrival_datetime, "DD/MM/YYYY");
      if (!date.isValid()) {
        validate = false;
        message = i18n.t("fill_field_date");
      }
      else if (date.isBefore(moment(), 'day') && this.submitted) {
        validate = false;
        message = i18n.t("fill_field_date_future")
      }
      else {
        validate = true;
        message = ""
      }

      validate = validate && this.submitted;

      return (
        <div className={validate ? 'box' : 'has-error box'} style={{position: 'relative'}}>
          <label className="title" htmlFor={name}>{title}</label>
            <input readOnly className="form-control input-field"
              onClick={this.toggleCalendar.bind(this)}
              value={this.state.data.arrival_datetime} />
            {this.showCalendar()}
          <span className="help-block">{message}</span>
        </div>
      );
    }

    if (name === "phone_country_code") {
      if (!this.submitted) {
        validate = true;
      }
      else {
        validate = this.state.data.phone_country_code.length > 0 && this.submitted;
      }

      return (
        <div className={validate ? 'box' : 'has-error box'}>
          <label className="title" htmlFor={name}>{title}</label>
          <CountryCodeDropdown
            lng={i18n.language}
            initialValue={this.state.data.phone_country_code}
            onSelect={(item) => {
              var data = this.state.data;
              data.phone_country_code = item;

              this.setState({
                data: data,
              });
            }}/>
          <span className="help-block">{validate ? "" : i18n.t("fill_field_date")}</span>
        </div>
      );
    }

    const placeholder = name === "arrivalTime" ? "HH:MM" : ""

    var val = this.state.data[name];
    validate = this.validation[name].isInvalid;
    return (
      <div className={validate ? 'has-error box' : "box"}>
        <label className="title" htmlFor={name}>{title}</label>
        <input type={type} className="input-field"
          name={name}
          value={val}
          onKeyPress={this.handleKeyPress}
          onChange={this.onFieldChanged}
          placeholder={placeholder}
        />
      <span className="help-block">{i18n.t(this.validation[name].message)}</span>
      </div>
    )
  }

  onFieldChanged = event => {
    event.preventDefault();

    var value = event.target.value;
    var data = this.state.data;

    // check if Pickup Time was changed
    if (event.target.name === "arrivalTime") {
      if (value.split(":").length > 2) return

      // if the input has 2 digits and doesn't already includes the :
      if (value.length === 2 && !value.includes(":")) {

        // if the : was included before the change, keep only the 1 digit
        if (data.arrivalTime.includes(":")) {
          value = value.slice(0, 1)
        }
        else {
          // else if there was no : before, add it
          value += ":"
        }
      }
    }

    data[event.target.name] = value;
    this.setState({data: data})
  }

  handleFormSubmit = event => {
    event.preventDefault();

    this.state.data.phone_number = this.state.data.phone_number.replace(/^0+|-/g, '')
    if (this.state.data.phone_number.includes(this.state.data.phone_country_code)) {
      var re = new RegExp("\\" + this.state.data.phone_country_code, "g");
      this.state.data.phone_number = this.state.data.phone_number.replace(re, "")
    }

    const validation = this.validator.validate(this.state.data);

    let date = moment(this.state.data.arrival_datetime, "DD/MM/YYYY", true)
    var validDate = date.isValid() && date.isSameOrAfter(moment(), 'day');

    this.setState({validation: validation, validDate: validDate});
    this.submitted = true;

    if (validation.isValid && validDate) this.checkIfChangesWereMade();
  }

  toggleCalendar() {
    this.setState({showCalendar: !this.state.showCalendar});
  }

  onChangeDate = option => {
    var data = this.state.data;
    data.arrival_datetime = moment(option).format("DD/MM/YYYY");

    // console.log(data);

    this.setState({
      data: data,
      showCalendar: false
    });
  }

  showCalendar() {
    if (!this.state.showCalendar) {
      return null;
    }
    else {
      const value = this.state.data.arrival_datetime != null ?
        moment(this.state.data.arrival_datetime, "DD/MM/YYYY").toDate()
        : new Date()

      return (
        <Calendar
          className="calendar"
          onChange={this.onChangeDate.bind(this)}
          prev2Label={null}
          next2Label={null}
          value={value}
          >
        </Calendar>
      )
    }
  }

  checkIfChangesWereMade() {
    var result = this.state.data.compareTo(this.originalData);
    if (result.match) {
      ReactGA.event({
        category: "customer",
        action: "order details",
        label: "no changes"
      })

      this.setState({showNoChangesView: true});
    }
    else {
      var dataForReview = {}
      dataForReview.data = this.state.data;
      dataForReview.comparison = result;
      dataForReview.order_id = this.id;

      ReactGA.event({
        category: "customer",
        action: "order details",
        label: "review"
      })

      this.onDataChange(dataForReview);
      this.history.push("/review");
    }
  }

  handleKeyPress = key => {
    if (key.key === 'Enter') {
      this.handleFormSubmit(key)
    }
  }

  render() {
    if (!this.state.data) {
      return null;
    }

    if (this.state.showDicardView) {
      return <DiscardView
        onNegativeClicked={(e) => {
          e.preventDefault();
          this.setState({showDicardView: false});
        }}
        onPositiveClicked={(e) => {
          e.preventDefault();
          this.onDataChange({});
          this.history.push("/");
        }}
        />
    }

    if (this.state.showNoChangesView) {
      return <NoChangesView
        onNegativeClicked={(e) => {
          e.preventDefault();

          ReactGA.event({
            category: "customer",
            action: "no changes made",
            label: "home"
          })

          this.history.push("/");
        }}
        onPositiveClicked={(e) => {
          e.preventDefault();

          ReactGA.event({
            category: "customer",
            action: "no changes made",
            label: "change info"
          })

          this.setState({showNoChangesView: false});
        }}
        />
    }

    this.validation = this.submitted ?
                      this.validator.validate(this.state.data) :
                      this.state.validation;

    return (
      <div className="screen-customer">
        <TopBanner
          backgroundColor="#C4262E"
          textColor="white"
          icon={icon}
          title={i18n.t("customer_coa")}
          subtitle={i18n.t("customer_update_details")}
          />

        <Container fluid style={{margin: 20, marginTop: 50}}>

          <Row>
            <Col md={4} sm={12}>
              <p className="title">{i18n.t("customer_barcode")}</p>
              <p className="detail-body">{this.state.data.receipt_no}</p>

              <p className="title">{i18n.t("first_name")}</p>
              <p className="detail-body">{this.state.data.first_name}</p>

              <p className="title">{i18n.t("last_name")}</p>
              <p className="detail-body">{this.state.data.last_name}</p>

              <p className="title">{i18n.t("customer_package_nr")}</p>
              <p className="detail-body">{this.state.data.number_of_packages}</p>

            </Col>
            <Col md={4} sm={12}>
              {this.createField(i18n.t("arrival_datetime"), "arrival_datetime")}
              {this.createField(i18n.t("arrivalTime"), "arrivalTime")}
              {this.createField(i18n.t("flight_no"), "flight_no")}
            </Col>
            <Col md={4} sm={12}>
              {this.createField(i18n.t("customer_country_code"), "phone_country_code")}
              {this.createField(i18n.t("phone_number"), "phone_number")}
              {this.createField(i18n.t("email"), "email")}
            </Col>
          </Row>

          <div className="buttons">
            <LockersButton link="/" className="button mobile-only" onClick={(e) => {
                // e.preventDefault();
                this.handleFormSubmit(e)
              }}>
              {i18n.t("customer_review_changes")}
            </LockersButton>

            <LockersButton className="button-grey" link="" onClick={(e) => {
                e.preventDefault();

                ReactGA.event({
                  category: "customer",
                  action: "cancel",
                  value: "order details"
                })

                this.setState({showDicardView: true});
              }}>
              {i18n.t("cancel")}
            </LockersButton>

            <LockersButton link="/" className="button web-only" onClick={(e) => {
                // e.preventDefault();
                this.handleFormSubmit(e)
              }}>
              {i18n.t("customer_review_changes")}
            </LockersButton>
          </div>

          <QuickLinks />

        </Container>
      </div>
    );
  }
}


export default withGA(CustomerDetailsScreen, false, true);
