import React, { Component } from 'react';

import { Container, Row, Col } from 'react-grid-system';
import ReactGA from 'react-ga';

import LockersButton, {
  NetworkManager,
  getIdFromUrl,
getErrorMessage
} from 'cph-lockers-library'

import QuickLinks from '../components/QuickLinks'
import qs from 'qs'

import i18n from '../i18n';

import icon from '../images/graphic_User_Keypads.svg'

import './Screen.css'

export default class CustomerCodeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: ["", "", "", ""],
      codeError: ""
    }

    this.submitted = false;
    this.fields = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef()
    ]

    this.onDataChange = this.props.onChangeData;
  }

  handleInputChange = event => {
    event.preventDefault();

    var value = event.target.value;

    var code = this.state.code;
    var index = parseInt(event.target.name);
    var newValue = value.replace(code[index], "")

    code[index] = newValue

    // clear the next field
    if (newValue.length > 0 && index < 3) {
      code[index + 1] = ""
    }

    this.setState({
      code: code
    });

    if (newValue.length === 0) return

    index++;
    if (index < 4) {
      this.fields[index].current.focus();
    }
  }

  handleFormSubmit = event => {
    event.preventDefault();

    var data = this.props.data();
    data.code = this.state.code.join('')
    this.submitted = true;

    let valid = this.validateCode();

    if (valid) {
      NetworkManager.post("/generate-order-token/", qs.stringify(data), true)
      .then((response) => {
        // console.log(response)
        if (response.data.error != null) {
          this.setState({codeError: i18n.t("customer_invalid_code")})
          return;
        }

        ReactGA.event({
          category: "customer",
          action: "valid pin code"
        })

        data.order_id = getIdFromUrl(response.data.url)
        this.onDataChange(data)

        // console.log(response.data.token)
        NetworkManager.setToken(response.data.token)
        this.props.history.push('/details');
      })
      .catch((error) => {
        this.setState({codeError: getErrorMessage(error.response)})

        ReactGA.event({
          category: "customer",
          action: "invalid pin code"
        })

        // console.log(error)
      })
    }
    else {
      if (!valid && this.state.codeError.length === 0) {
        this.setState({codeError: "The code is invalid. Please try again."});
      }
      else if (valid && this.state.codeError.length > 0) {
        this.setState({codeError: ""});
      }
    }
  }

  handleKeyPress = key => {
    if (key.key === 'Enter') {
      this.handleFormSubmit(key)
    }
  }

  validateCode() {
    var valid = true;

    var code = this.state.code;
    // console.log(code)
    for (var i = 0; i < code.length; ++i) {
      if (code[i].length !== 1) {
        // console.log(code[i])
        valid = false;
      }
    }

    return valid;
  }

  resendCode = event => {
    event.preventDefault();

    var data = this.props.data();

    NetworkManager.post("/generate-code/", qs.stringify(data), true)
    .then((response) => {
      // console.log(response)
      if (response.data.error != null) {
        this.setState({codeError: getErrorMessage(response)})
        return;
      }
    })
    .catch((error) => {
      // console.log(error)
      this.setState({codeError: getErrorMessage(error.response)})
    })
  }

  render() {
    this.validation = this.submitted ?
                      this.validateCode() :
                      this.state.validation

    return (
      <div className="screen-customer">

        <div className="screen">

          <img style={{
              marginTop: 30,
              marginBottom: 30
            }} height="100" width="100" src={icon} alt=""/>

          <p className="start-header">{i18n.t("customer_welcome")}}</p>
          <p className="header" style={{marginTop: 0}}>{i18n.t("customer_coa")}</p>
          <p style={{color: "black", fontSize: 14, fontWeight: '200', textAlign: 'center'}}>{i18n.t("customer_enter_digits")}</p>

          <span className="help-block" style={{marginTop: 20}}>{this.state.codeError}</span>

          <Container fluid>
            <Row >
              <Col xs={3} className="code-field-col">
                <input className="code-field" autoFocus
                  name="0"
                  value={this.state.code[0]}
                  onChange={this.handleInputChange}
                  ref={this.fields[0]}
                  type="text"
                  onKeyPress={this.handleKeyPress}
                />
              </Col>

              <Col xs={3} className="code-field-col">
                <input className="code-field"
                  name="1"
                  value={this.state.code[1]}
                  onChange={this.handleInputChange}
                  ref={this.fields[1]}
                  type="text"
                  onKeyPress={this.handleKeyPress}
                />
              </Col>

              <Col xs={3} className="code-field-col">
                <input className="code-field"
                  name="2"
                  value={this.state.code[2]}
                  onChange={this.handleInputChange}
                  ref={this.fields[2]}
                  type="text"
                  onKeyPress={this.handleKeyPress}
                />
              </Col>

              <Col xs={3} className="code-field-col">
                <input className="code-field"
                  name="3"
                  value={this.state.code[3]}
                  onChange={this.handleInputChange}
                  ref={this.fields[3]}
                  type="text"
                  onKeyPress={this.handleKeyPress}
                />
              </Col>
            </Row>
          </Container>

          <p className="resend-code"
            onClick={this.resendCode}>{i18n.t("customer_resend_code")}</p>

          <LockersButton link="" onClick={this.handleFormSubmit} className="button">
            {i18n.t("continue")}
          </LockersButton>

          <QuickLinks />

        </div>

      </div>
    );
  }
}
