import React, { Component } from 'react';

import { BrowserRouter } from 'react-router-dom'
import Customer from './Customer'

import { NetworkManager, initializeGA, CUSTOMER_BASENAME } from 'cph-lockers-library'

import './App.css';

class App extends Component {
  constructor(props) {
    super(props)

    NetworkManager.init()

    initializeGA(process.env.REACT_APP_GA_ID, false)
  }

  render() {
    return (
      <BrowserRouter className="home" basename={CUSTOMER_BASENAME}>
        <div className="home">
          <Customer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
