import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import i18n from '../i18n';

import icon from '../images/Group 59.svg';
import enIcon from '../images/EN.svg';
import dkIcon from '../images/DK.svg';

class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {enLng: true}
  }

  render() {
    return (
      <div className="footer">
        <div className="footer-items">
          <a className="footer-item quick-link text"
            target="_blank"
            rel="noopener noreferrer"
            href={"https://sites.google.com/neutral.gs/coa/tncs-en"}
            onClick={(e) => {
              ReactGA.event({
                category: "customer",
                action: "footer",
                label: "tnc"
              })
            }}>{i18n.t("tnc")}</a>

          <a className="footer-item quick-link text"
            target="_blank"
            rel="noopener noreferrer"
            href={"https://sites.google.com/neutral.gs/coa/pp-en"}
            onClick={(e) => {
              ReactGA.event({
                category: "customer",
                action: "footer",
                label: "privacy policy"
              })
            }}>{i18n.t("privacy_policy")}</a>
        </div>

        <div className="footer-items">
          <img src={dkIcon} className="footer-item quick-link icon-right" alt=""
            style={{opacity: !this.state.enLng ? 1 : 0.3}}
            onClick={(e) => {
              e.preventDefault()

              ReactGA.event({
                category: "customer",
                action: "footer",
                label: "language dk"
              })

              this.setState({enLng: false})
              i18n.changeLanguage("dk");
            }}/>

          <img src={enIcon} className="footer-item quick-link" alt=""
            style={{opacity: this.state.enLng ? 1 : 0.3}}
            onClick={(e) => {
              e.preventDefault()

              ReactGA.event({
                category: "customer",
                action: "footer",
                label: "language en"
              })

              this.setState({enLng: true})
              i18n.changeLanguage("en");
            }}/>
        </div>

        <img src={icon} height="42" width="146" alt="" className="logo" />
      </div>
    );
  }
}

export default withRouter(Footer)
